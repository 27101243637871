import { Event, TrackEntry } from '@pixi-spine/all-4.1';

import AudioApi from '@phoenix7dev/audio-api';

import { FishKind, ISongs } from '../../config';
import { SpineInterface } from '../../config/spine.generated';
import { GameMode } from '../../consts';
import i18n from '../../i18next';
import { transitionToBaseGame } from '../../utils';
import Tween from '../animations/tween';
import { BgmControl } from '../bgmControl/bgmControl';
import AutoResizeText from '../components/autoResizeText';
import ButtonContainer from '../components/buttonContainer';
import ViewContainer from '../components/container';
import { layerFishingGamble, layerUI } from '../components/layers/layers';
import { TickerSpine } from '../components/spine';
import { EventTypes, eventManager, gameLayout } from '../config';

import { FishingLayout } from './config';
import { FishIcon } from './icon/fishIcon';
import { fishingGambleLoseTextStyles } from './textStyle';

type TblRange = [number, number];
interface GambleData {
  id: number;
  result: 'WIN' | 'LOST';
  range: TblRange;
}

class FishGamble extends ViewContainer {
  //private rod: TickerSpine<'fg_indi'>;
  private sign: TickerSpine<'fg_indi'>;
  private meter: TickerSpine<'fg_indi_meter'>;

  private winAnim: TickerSpine<'fg_indi_meter'>;

  private fishKind: FishKind;
  private result: GambleData | null = null;

  private gambleStartBtn: ButtonContainer;

  private icon: FishIcon;

  private title: TickerSpine<'fg_indi'>;

  private stopEventName: string;

  private loseText: AutoResizeText;

  private duringEndAnimation: boolean;

  private layout: 'landscape' | 'portrait';
  constructor() {
    super();

    this.fishKind = 'Rank1';
    this.layout = 'landscape';
    this.stopEventName = '';
    this.duringEndAnimation = false;

    this.sign = new TickerSpine('fg_indi');
    this.sign.state.setAnimation(0, 'sgin', true);

    this.meter = new TickerSpine('fg_indi_meter');
    this.meter.state.setAnimation(0, 'wait', true);

    this.winAnim = new TickerSpine('fg_indi_meter');
    this.winAnim.state.setAnimation(0, 'win_in', false);
    this.winAnim.visible = false;

    this.icon = new FishIcon('Rank7');
    this.title = new TickerSpine('fg_indi');
    this.title.state.setAnimation(0, 'title', false);

    this.gambleStartBtn = new ButtonContainer(
      FishingLayout.Gamble.gambleStartBtnArea.x,
      FishingLayout.Gamble.gambleStartBtnArea.y,
      FishingLayout.Gamble.gambleStartBtnArea.w,
      FishingLayout.Gamble.gambleStartBtnArea.h,
      this.onGambleStartClick.bind(this),
    );

    this.loseText = new AutoResizeText(i18n.t('fishing.gamble.lose'), fishingGambleLoseTextStyles);
    this.loseText.anchor.set(0.5);
    this.loseText.visible = false;

    this.initSpineListener();
    this.addChild(this.sign, this.meter, this.winAnim, this.icon, this.title, this.loseText, this.gambleStartBtn);

    eventManager.on(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.on(EventTypes.RESIZE, this.applicationResize.bind(this));

    eventManager.on(EventTypes.FISHING_GAMBLE_START_WAIT, this.startWait.bind(this));

    eventManager.on(EventTypes.FISHING_CHALLENGE_GOTCHA_END, this.onEndGotcha.bind(this));

    eventManager.on(EventTypes.GAMBLE_SELECTED, this.setFishKind.bind(this));
    eventManager.on(EventTypes.FISHING_GAMBLE_METER_START, this.onStartMeterAction.bind(this));

    this.parentLayer = layerFishingGamble;

    this.visible = false;

    this.gambleStartBtn.parentLayer = layerUI;

    //test
    //this.visible = true;
    //eventManager.emit(EventTypes.GAMBLE_SELECTED, 'Rank3');
    //this.start('Rank3', 420, false);
  }

  private initSpineListener() {
    this.meter.state.addListener({
      event: (_entry: TrackEntry, event: Event) => {
        if (event.data.name === this.stopEventName && !this.duringEndAnimation) {
          this.onEndAnimation();
        } else if (event.data.name === 'sound_white') {
          AudioApi.play({ type: ISongs.XT005S_gamble_indicator_white, loop: false, stopPrev: true });
        } else if (event.data.name === 'sound_red') {
          AudioApi.play({ type: ISongs.XT005S_gamble_indicator_red, loop: false, stopPrev: true });
        } else if (event.data.name === 'sound_green') {
          AudioApi.play({ type: ISongs.XT005S_gamble_indicator_green, loop: false, stopPrev: true });
        } else if (event.data.name === 'win') {
          if (this.result?.result === 'WIN') {
            AudioApi.play({ type: ISongs.XT005S_gamble_fishlamp, loop: false, stopPrev: true });
            this.sign.state.setAnimation(0, 'sgin_win', true);
            this.winAnim.state.setAnimation(0, 'win_in', false);
            this.winAnim.state.addAnimation(0, 'win_lp', true);
            this.winAnim.visible = true;
          }
        }
      },
      complete: (entry: TrackEntry) => {
        if (
          entry.animation?.name.includes('hi') ||
          entry.animation?.name.includes('mid') ||
          entry.animation?.name.includes('low')
        ) {
          if (!this.duringEndAnimation) {
            this.onEndAnimation();
          }
        }
      },
    });
  }
  private onGambleStartClick() {
    this.duringEndAnimation = false;

    this.gambleStartBtn.interactive = false;
    this.icon!.visible = false;
    this.title.visible = false;
    this.winAnim.visible = false;

    this.meter.state.setAnimation(0, 'wait', true);
    eventManager.emit(EventTypes.FISHING_GAMBLE_START);

    BgmControl.stopAll();
    AudioApi.play({ type: ISongs.XT005S_bg_gamble_loop, loop: true });
  }

  private onStartMeterAction() {
    const MeterActConvert: SpineInterface['fg_indi_meter']['animations'][] = [
      'hi',
      'mid2',
      'mid2',
      'mid2',
      'mid1',
      'mid1',
      'low2',
      'low1',
      'mid2',
      'mid2',
      'mid1',
      'mid1',
      'mid1',
      'low2',
      'low2',
      'low2',
      'low1',
      'low1',
      'low1',
      'low1',
    ];
    this.meter.state.setAnimation(0, this.result?.id != undefined ? MeterActConvert[this.result?.id!]! : 'low1', false);
  }

  private setFishKind(fishKind?: FishKind) {
    const RankToClimaxSkin: Record<FishKind, SpineInterface['fg_indi_meter']['skins']> = {
      Rank1: 'pt01',
      Rank2: 'pt02',
      Rank3: 'pt03',
      Rank4: 'pt04',
      Rank5: 'pt05',
      Rank6: 'pt06',
      Rank7: 'pt07',
    };

    this.fishKind = fishKind ?? 'Rank7';
    this.icon.setFishKind(this.fishKind);
    this.meter.skeleton.setSkinByName(RankToClimaxSkin[this.fishKind]);
    this.winAnim.skeleton.setSkinByName(RankToClimaxSkin[this.fishKind]);
  }

  private onChangeMode(settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    isRetrigger?: boolean;
    fishKind?: FishKind;
  }) {
    if (settings.mode === GameMode.FISH_GAMBLE) {
      this.meter.state.setAnimation(0, 'wait', true);
      this.sign.state.setAnimation(0, 'sgin', true);
      this.icon.visible = true;
      this.title.visible = true;
      this.winAnim.visible = false;
      this.visible = false;
    } else {
      this.visible = false;
    }
  }

  private startWait(_fishKind: FishKind, target: number, _result: boolean) {
    //this.fishKind = fishKind; //TO DO, BE結合後データの持ち方によってここでもらったfish使うか検討 ※現状は成功時と失敗時で魚が異なる

    this.result = this.getIdByValue(target);
    this.visible = true;
    this.gambleStartBtn.interactive = true;

    this.stopEventName = `stop${this.result?.id! + 1}`;

    this.visible = true;
    AudioApi.play({ type: ISongs.XT005S_gamble_trigger });
  }
  private onEndAnimation() {
    this.duringEndAnimation = true;
    const meterStop: SpineInterface['fg_indi_meter']['animations'] =
      this.result?.id! >= 9
        ? (`stop_${this.result?.id! + 1}` as SpineInterface['fg_indi_meter']['animations'])
        : (`stop_0${this.result?.id! + 1}` as SpineInterface['fg_indi_meter']['animations']);

    this.meter.state.setAnimation(0, meterStop, true);

    if (this.result?.result === 'LOST') {
      //this.miss.state.setAnimation(0, 'miss', true);
      //this.miss.visible = true;
      this.sign.state.setAnimation(0, 'sgin_lose', false);
      eventManager.emit(EventTypes.FISHING_GAMBLE_LOSE);
      this.loseText.visible = true;
      const delay = Tween.createDelayAnimation(3000);
      delay.addOnComplete(() => {
        transitionToBaseGame(() => {
          this.loseText.visible = false;
          this.visible = false;
        });
      });
      delay.start();
    } else {
      //this.sign.state.setAnimation(0, 'sgin_win', false);
      AudioApi.play({ type: ISongs.XT005S_gamble_indicator_fix, stopPrev: true });

      const delay = Tween.createDelayAnimation(2000);
      delay.addOnComplete(() => {
        eventManager.emit(EventTypes.FISHING_CHALLENGE_GOTCHA_START, this.fishKind);
      });
      delay.start();
    }

    AudioApi.stop({ type: ISongs.XT005S_bg_gamble_loop });
    AudioApi.stop({ type: ISongs.XT005S_gamble_aori_fast });
    AudioApi.stop({ type: ISongs.XT005S_gamble_aori_mid });
    AudioApi.stop({ type: ISongs.XT005S_gamble_aori_slow });
  }

  private onEndGotcha() {
    this.visible = false;
  }

  private getIdByValue(value: number): GambleData | null {
    const data: GambleData[] = [
      { id: 0, result: 'WIN', range: [0, 49] },
      { id: 1, result: 'WIN', range: [50, 99] },
      { id: 2, result: 'WIN', range: [100, 149] },
      { id: 3, result: 'WIN', range: [150, 199] },
      { id: 4, result: 'WIN', range: [200, 249] },
      { id: 5, result: 'WIN', range: [250, 299] },
      { id: 6, result: 'WIN', range: [300, 349] },
      { id: 7, result: 'WIN', range: [350, 399] },
      { id: 8, result: 'LOST', range: [400, 449] },
      { id: 9, result: 'LOST', range: [450, 499] },
      { id: 10, result: 'LOST', range: [500, 549] },
      { id: 11, result: 'LOST', range: [550, 599] },
      { id: 12, result: 'LOST', range: [600, 649] },
      { id: 13, result: 'LOST', range: [650, 699] },
      { id: 14, result: 'LOST', range: [700, 749] },
      { id: 15, result: 'LOST', range: [750, 799] },
      { id: 16, result: 'LOST', range: [800, 849] },
      { id: 17, result: 'LOST', range: [850, 899] },
      { id: 18, result: 'LOST', range: [900, 949] },
      { id: 19, result: 'LOST', range: [950, 999] },
    ];

    for (const item of data) {
      const [low, high] = item.range;
      if (value >= low && value <= high) {
        return item;
      }
    }
    return null;
  }

  private applicationResize = (width: number, height: number): void => {
    const layout = width > height ? FishingLayout.Gamble.landscape : FishingLayout.Gamble.portrait;
    const scale = layout.scale;

    const iconLayout = width > height ? gameLayout.fishingRoulette.landscape : gameLayout.fishingRoulette.portrait;

    this.sign.position.set(layout.Sign.x, layout.Sign.y);
    this.meter.position.set(layout.meter.x, layout.meter.y);
    this.winAnim.position.set(layout.meter.x, layout.meter.y);
    this.title.position.copyFrom(iconLayout.title);
    this.icon.position.copyFrom(iconLayout.icon);
    this.loseText.position.copyFrom(layout.lose);

    this.title.scale.set(iconLayout.title.scale);
    this.icon.scale.set(iconLayout.icon.scale);
    this.meter.scale.set(scale.meter);
    this.winAnim.scale.set(scale.meter);
    this.loseText.scale.set(scale.lose);

    if (width > height) {
      this.layout = 'landscape';
    } else {
      this.layout = 'portrait';
    }
  };
}

export default FishGamble;
