import { FishKind } from '../config';
import i18n from '../i18next';
import { fishToMultiplier } from '../slotMachine/fishing/icon/config';

export const getFishRankTitleText = (): string => {
  return i18n.t('fishing.challenge.meter.title');
};

export const getFishRankText = (fishKind: FishKind): string => {
  const rankToRankText: Record<FishKind, string> = {
    Rank1: i18n.t('fishing.challenge.meter.rank1.rank'),
    Rank2: i18n.t('fishing.challenge.meter.rank2.rank'),
    Rank3: i18n.t('fishing.challenge.meter.rank3.rank'),
    Rank4: i18n.t('fishing.challenge.meter.rank4.rank'),
    Rank5: i18n.t('fishing.challenge.meter.rank5.rank'),
    Rank6: i18n.t('fishing.challenge.meter.rank6.rank'),
    Rank7: i18n.t('fishing.challenge.meter.rank7.rank'),
  };
  return rankToRankText[fishKind];
};

export const getFishNameText = (fishKind: FishKind): string => {
  const rankToNameText: Record<FishKind, string> = {
    Rank1: i18n.t('fishing.challenge.meter.rank1.name'),
    Rank2: i18n.t('fishing.challenge.meter.rank2.name'),
    Rank3: i18n.t('fishing.challenge.meter.rank3.name'),
    Rank4: i18n.t('fishing.challenge.meter.rank4.name'),
    Rank5: i18n.t('fishing.challenge.meter.rank5.name'),
    Rank6: i18n.t('fishing.challenge.meter.rank6.name'),
    Rank7: i18n.t('fishing.challenge.meter.rank7.name'),
  };
  return rankToNameText[fishKind];
};

export const getFishMultiplierValueText = (fishKind: FishKind): string => {
  const rankToMultiplierText: Record<FishKind, string> = {
    Rank1: '3015, 3050, 3085, 3120, 3155, 3190, 3225, 3260, 3295, 3330',
    Rank2: '1185, 1220, 1255, 1290, 1325, 1360, 1395, 1430, 1465, 1500',
    Rank3: '450, 475, 500, 525, 550, 575, 600, 625, 650, 675',
    Rank4: '165, 180, 195, 210, 225, 240, 255, 270, 285, 300',
    Rank5: '70, 75, 80, 85, 90, 95, 100, 105, 110, 115',
    Rank6: '30, 31, 32, 33, 34, 35, 36, 37, 38, 39',
    Rank7: '10, 11, 12, 13, 14, 15, 16, 17, 18, 19',
  };
  return rankToMultiplierText[fishKind];
};

export const getFishDescText = (fishKind: FishKind): string => {
  return i18n.t('fishing.challenge.meter.desc', { multiplier: fishToMultiplier[fishKind] });
};
